import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import 'uikit/dist/css/uikit.css'
import "../styles/special-menu.scss"
import SecondaryLayout from "../layouts/secondary-layout"



const SpecialMenu = ({ pageContext }) => {

  const data = useStaticQuery(graphql`
        {
          strapiMenuIndex {
            Seo {
                metaDescription
                metaTitle
              }
          }

          strapiSiteSettings{
            Logo{
                url
                localFile{
                    childImageSharp{
                        gatsbyImageData(
                            width: 180
                            placeholder: NONE
                            formats: [AUTO, WEBP]
                        )
                    }
                }
            }
          }
        }
        `
  )


  return (
    <SecondaryLayout SEOTitle="Wine Tasting Experience | La Tagliata | Italian Restaurant London" SEODescription={data.strapiMenuIndex.Seo.metaDescription}>

      <>
        <div>
          <div className="special-menu-container">
            <div className="special-menu-content">

              <div className="head">
                <h2 className="menu-title">Wine tasting experience menu £ 60 p/p</h2>
                {/* <b></b> */}
                <span>Our Menu includes 3 courses with a selection of wines by glass of Bolgheri cantina</span>
              </div>

              <div className="dishes">
                <div className="type">
                  <h3>Starter</h3>
                  <div className="dish">
                    <span>Pane e olive italiane</span>
                    <p>Homemade rosemary focaccia with salt, olives, cherry tomatoes and Sardinian Carasau bread <b>(VG)</b></p>
                  </div>
                  <div className="dish">
                    <span>Bruschette delle chef</span>
                    <p>Mix of bruschetta with a selection of topping</p>
                  </div>
                </div>

                <div className="type">
                  <h3>Main course</h3>
                  <div className="dish">
                    <span>La Tagliata di manzo</span>
                    <p>Finest grilled British beef fillet served with rocket salad, cherry tomatoes, Grana Padano cheese</p>
                  </div>
                  <div className="dish">
                    <span>Lo Spada</span>
                    <p>Grilled swordfish steak served with asparagus and citrus</p>
                  </div>
                  <div className="dish">
                    <span>Tagliolini crab</span>
                    <p>Long Pasta with handpicked crab from Devon, cherry tomatoes, chilli, garlic and parsley (May contain pieces of shell)</p>
                  </div>
                  <div className="dish">
                    <span>Tagliatelle ai porcini</span>
                    <p>Long Pasta with forest porcini mushrooms, rocket leaves, Parmesan shavings, cherry tomatoes and truffle oil <b>(V)</b></p>
                  </div>
                </div>

                <div className="type">
                  <h3>Dessert</h3>
                  <div className="dish">
                    <span>Tiramisu - Panna cotta - Tagliere di formaggi</span>
                    <p></p>
                  </div>
                </div>
              </div>

              <div className="disclaimer">
                <span className="allergies">(VG) vegan, (V) vegetarian, (N) nuts, (S) spicy</span>
                <p>Please advise a member of staff of any allergies or dietary requirements. To have the best possible experience please inform a
                  member of staff about how we can improve during your time with us. A discretionary 12.5% service charge will be added to your bill,
                  which is shared in full by the staff team.</p>
              </div>

            </div>
          </div>
        </div>
      </>



    </SecondaryLayout>
  )
}

export default SpecialMenu